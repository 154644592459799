<template>
  <v-app class="grey light-blue lighten-4">
    <!-- -->
    
    <v-app-bar height="75" dark color="indigo darken-4">
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <h3 class="mx-7" >AUDIDOC</h3>
      <v-spacer></v-spacer>
      <v-btn color="white" fab small>
              <Perfil />
            </v-btn>
      <v-btn
              class=""
              @click="$store.commit('redireccion', '/admin')"
              icon
            >
              <v-icon> mdi-home </v-icon>
            </v-btn>
            
            <v-btn
              class=""
              fab
              small
              text
              @click="$store.commit('redireccion', '/')"
            >
              <v-icon color="red"> mdi-exit-to-app </v-icon>
            </v-btn>
    </v-app-bar>

    <v-main class="mx-5 my-5">
      
        <router-view></router-view>
      
    </v-main>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list>
        <v-list-item
          v-for="([icon, text, ruta], i) in navigation"
          :key="i"
          link
          @click="$store.commit('redireccion', ruta)"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>
<script>

import Perfil from '../components/Perfil'
export default {
  components: {Perfil},
  data: () => ({
    navigation: [
      ["mdi-logout", "Salir", "/"],
    ],
   
    
    
    drawer: false,
    group: null,
  }),
  created() {
    //this.iniciar("listar");
  },

  watch: {
    
  },
  methods: {
  
  },
};
</script>