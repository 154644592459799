<template>
  <v-container fluid class="fill-height">
    <v-tooltip>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          fab
          text
          color="blue darken-4"
          v-on="on"
          v-bind="attrs"
          @click="perfil = !perfil"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <span>Ver Perfil</span>
    </v-tooltip>
    <v-dialog v-model="perfil" max-width="700">
      <v-sheet color="" rounded=""  class="mx-auto" elevation="5">
        <v-row>
          <v-col>
            <v-container>
              <v-sheet
                height=""
                color="indigo darken-4"
                elevation="5"
                rounded=""
                dark
              >
                <!-- <v-img
                height="60"
                contain
                class=""
                :src="require('../../assets/img.png')"
              > 
              </v-img>-->
                <div
                  v-text="'Usuario'"
                  class="headline font-weight-light text-center"
                ></div>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet>
              <v-list two-line>
                <v-subheader
                  v-text="'Datos usuario'"
                  class="header font-weight-light ml-3"
                ></v-subheader>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="black"> mdi-account</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="dataUser.nombre"
                    ></v-list-item-title>
                    <v-list-item-subtitle>Nombre</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="black"> mdi-account-box-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="dataUser.identificacion"
                    ></v-list-item-title>
                    <v-list-item-subtitle>identificacion</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="black"> mdi-email </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="dataUser.correo"
                    ></v-list-item-title>
                    <v-list-item-subtitle>Personal</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="black"> mdi-map-marker </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="dataUser.nombre_sector">  </v-list-item-title>
                    <v-list-item-subtitle
                      >Area</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon @click="consultar_produccion()">
                    <v-btn icon>
                       <v-icon color="black"> mdi-file-document-multiple-outline </v-icon>
                    </v-btn>
                   
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="produccion">  </v-list-item-title>
                    <v-list-item-subtitle
                      >Documentos digitalizados</v-list-item-subtitle
                    >
                  </v-list-item-content>
                   <v-list-item-icon @click="actualizar_produccion()">
                     <v-btn icon>
                        <v-icon color="black"> mdi-upload </v-icon>
                     </v-btn>
                   
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row class="ma-5">
          <v-col>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Actulizar acceso
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <v-text-field label="Ingresa tu usuario" filled rounded dense v-model="password.user"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field 
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      
                        :type="show1 ? 'text' : 'password'"  
                        @click:append="show1 = !show1"
                        label="Ingresa tu clave actual"
                        filled rounded dense v-model="password.old"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      label="Ingresa tu nueva clave"
                      @click:append="show1 = !show1"
                      :type="show1 ? 'text' : 'password'"   filled rounded dense v-model="password.new"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      label="Ingresa tu nueva clave"
                      @click:append="show1 = !show1"
                      :type="show1 ? 'text' : 'password'"   filled rounded dense v-model="password.new_confirmation"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-end">
                      <v-btn @click="update_password(password)" :disabled="validator_update_password || intentos_fallidos>=3" dark color="indigo darken-4">
                        Actualizar contraseña
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            
          </v-col>
        </v-row>
      </v-sheet>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      perfil: false,
      produccion: 0,
      password: {
        user: "",
        old: "",
        new: "",
        new_confirmation: ""
      },
      show1: false,
      intentos_fallidos: 0,
    };
  },
  created() {
    console.log(this.dataUser);

    if(this.$store.state.documentos.documentos_subidos==0){
       this.consultar_produccion()
    }else{
      this.produccion = this.$store.state.documentos.documentos_subidos
    }
  },
  methods: {
    async update_password(password){
      try {
        console.log(password);

        let buscar_login = JSON.stringify({
          usuario: password.user,
          clave: password.old,
        });

        buscar_login = encodeURIComponent(buscar_login);

        let response = await this.$store.dispatch('llamado_get',{
          url: `https://siett.educandote.co/parse/classes/prueba01Login?where=${buscar_login}`,
          tipo_header: 'parse'
        });

        console.log(response);

        if (response.data.results.length == 0) {
          
          
          this.intentos_fallidos++;

          if(this.intentos_fallidos==3){
            await this.$store.dispatch('utils/SAVE_ACTIVITY_USER',{data: {
              date: Date.parse(new Date()),
              ...password
            }, action: "ACTUALIZAR_CLAVE_MALISIOSO"});
            this.$store.commit('error', 'Jumm... Detectamos actividad malisiosa. (Att: La Administración)');
            this.$router.push('/');
          }else{
            this.$store.commit('error', 'Las credenciales de acceso no existen, por favor verifiquelas');
          }

          



        } else {
             
          let update_password = await this.$store.dispatch('llamado_put',{
            url: `https://siett.educandote.co/parse/classes/prueba01Login/${response.data.results[0].objectId}`,
            body: {
              clave: password.new_confirmation,
            },
            tipo_header: 'parse'
          });

          console.log(update_password);
          await this.$store.dispatch('utils/SAVE_ACTIVITY_USER',{data: {
              date: Date.parse(new Date()),
              ...password
          }, action: "ACTUALIZAR_CLAVE_EXITOSO"});

          this.$store.commit('exitoso', 'Las credenciales de acceso han sido actualizadas de forma exitosa')
          
          this.password= {
              user: "",
              old: "",
              new: "",
              new_confirmation: ""
            };
          this.show1 = false;
          this.intentos_fallidos = 0;
        }

      } catch (error) {
        console.log(error);
      }
    },
    async consultar_produccion(){
      try {        
          let response =  await this.$store.dispatch('GET_CANTIDAD_DOCUMENTOS_SUBIDOS')
          console.log(response)
          if(response.sucess){
            
            if(response.data.data.results.length>0){
              this.produccion = response.data.data.results[0].documentos_subidos
            }else{
              this.produccion = 0
            }
            
          }else{
            console.log('No es posible conseguir los datos')
          }

        } catch (error) {
          console.log(error)
        }
    },
    async actualizar_produccion(){
      try {
        let response = await this.$store.dispatch('SET_API_DOCUMENTOS_SUBIDOS')
        console.log(response)
        if(response){
            this.$store.commit(
                "exitoso",
                    "Su producción actual es de: "+ this.$store.state.documentos.documentos_subidos+ " Los datos han sido almacenados en la plataforma"
                );
            this.$store.commit('RESET_DOCUMENTOS_SUBIDOS',0)   
            this.consultar_produccion()           
        }else{
              this.$store.commit('error',"Su prodoccion actual es de: "+ this.$store.state.documentos.documentos_subidos+ " Los datos NO han sido almacenados en la plataforma")
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    ...mapState("dataUser", ["dataUser"]),
    validator_update_password(){
      if( (this.password.user!= '' && this.password.old!='' && this.password.new!='' && this.password.new_confirmation!='' && this.password.new_confirmation == this.password.new )){
        return false;
      }  
      return true;
    }
  },
};
</script>